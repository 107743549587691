import React, {useMemo, useEffect, useState} from 'react';
import {RouteComponentProps, useLocation} from '@reach/router';
import {Button, createStyles, makeStyles, Theme, Typography} from '@material-ui/core';
import NavigationDrawer from './navigation/NavigationDrawer';
import Role from "myfitworld-model/dist/enums/Role";
import {auth, firestore} from "../firebase";
import OrganizationSwitcher from "./organizations/OrganizationSwitcher";
import {getCurrentUserRole} from "../api/usersApi";
import {useUserProvider} from "../providers/UserProvider";
import {useStoreState} from "pullstate";
import globalState from "../globalState";
import {Organization, User} from "myfitworld-model";
import app from "firebase";
import { setNotificationsToBeActive } from '../api/notificationApi';
import BusinessCenterOutlinedIcon from '@material-ui/icons/BusinessCenterOutlined';
import DiscountBadge from "../../src/components/DiscountBadge";
import { Calendar as CalendarIcon } from '../theme/MenuIcons';
import {Barbel as BarbelIcon} from '../theme/MenuIcons'
import {Connections as ConnectionsIcon} from '../theme/MenuIcons'
import {Content as ContentIcon} from '../theme/MenuIcons'
import {Profile as ProfileIcon} from '../theme/MenuIcons'
import {Premium as PremiumIcon} from '../theme/MenuIcons'
import {Marketplace as MarketplaceIcon} from '../theme/MenuIcons'
import {StripeConnect as StripeConnectIcon} from '../theme/MenuIcons'
import {Gear as GearIcon} from '../theme/MenuIcons'
import {MealPlan as MealPlanIcon} from '../theme/MenuIcons'
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';
import { useMfwThemeProvider } from 'myfitworld-utils';
import { MenuType } from '../types/other';
import { buildUrl, configMainRoutesConsts, configRoutesMenuLabels } from '../config/route';
import { useIntl } from 'react-intl';
import globalMessages from '../messages';
import componentMessages from './messages';
import { isMarketplacePage } from '../hooks/marketplace/helpers';
import Box from "@material-ui/core/Box/Box";
import SectionTitleWithTextTutorialsContent from "../pages/tutorials/SectionTitleWithTextTutorialsContent";

const isStageEnv = process.env.REACT_APP_ENV === "stage";

export interface AppLayoutProps extends RouteComponentProps {
  children: React.ReactNode;
}

interface MenuItemsInterface {
  label: configRoutesMenuLabels;
  route: string;
  icon: JSX.Element;
}

const MenuItemsList : MenuItemsInterface[] = [
  {
  label : configRoutesMenuLabels.organizationsManagementPage,
  route : configMainRoutesConsts.organizations,
  icon: <BarbelIcon />,
},{
  label : configRoutesMenuLabels.nutritionPlanPage,
  route :configMainRoutesConsts.nutritionPlan,
  icon: <MealPlanIcon />
},{
  label : configRoutesMenuLabels.superAdminUserManagementPage,
  route :configMainRoutesConsts.users,
  icon: <ConnectionsIcon />,
}, {
  label : configRoutesMenuLabels.corpOrganizationsManagementPage,
  route :configMainRoutesConsts.corporateOrganizations,
  icon: <BusinessCenterOutlinedIcon/>,
}, {
  label : configRoutesMenuLabels.reportsManagementPage,
  route :configMainRoutesConsts.superadminReports,
  icon: <BusinessCenterOutlinedIcon/>,
},
{
  label : configRoutesMenuLabels.contentManagementPage,
  route :configMainRoutesConsts.content,
  icon: <ContentIcon />,
},
{
  label : configRoutesMenuLabels.configurationManagementPage,
  route :configMainRoutesConsts.configuration,
  icon: <GearIcon />
},
{
  label : configRoutesMenuLabels.schedulePage,
  route :configMainRoutesConsts.schedule,
  icon: <CalendarIcon />
},
{
  label : configRoutesMenuLabels.myOrganizationPage,
  route :configMainRoutesConsts.organization,
  icon: <BarbelIcon />
},
{
  label : configRoutesMenuLabels.clientManagementPage,
  route :configMainRoutesConsts.clients,
  icon: <ConnectionsIcon />
},
{
  label : configRoutesMenuLabels.editProfilePage,
  route :configMainRoutesConsts.profile,
  icon: <ProfileIcon />
},
{
  label : configRoutesMenuLabels.tutorialsPage,
  route :configMainRoutesConsts.tutorials,
  icon: <OndemandVideoIcon />
},{
  label : configRoutesMenuLabels.stripeConnectPage,
  route :configMainRoutesConsts.stripeConnect,
  icon: <StripeConnectIcon />
},{
  label : configRoutesMenuLabels.billingPage,
  route :configMainRoutesConsts.billing,
  icon: <DiscountBadge />
},
{
  label : configRoutesMenuLabels.editOrgProfilePage,
  route : configMainRoutesConsts.orgProfile,
  icon: < ProfileIcon/>
},
{
  label : configRoutesMenuLabels.marketplaceManagementPage,
  route : buildUrl(configMainRoutesConsts.marketplace, ["en"]),
  icon: < MarketplaceIcon/>
},
{
  label : configRoutesMenuLabels.clientLandingPage,
  route : configMainRoutesConsts.root,
  icon: < ProfileIcon/>
},
{
  label: configRoutesMenuLabels.premiumPage,
  route: configMainRoutesConsts.premium,
  icon: <PremiumIcon/>
}
];

const filterOutMenu = (list: string[]) => {
  return list.reduce<MenuItemsInterface[]>((acc, label) => {
     const item = MenuItemsList.find(e => e.label === label);
     if (item) {
       acc.push(item);
     }
     return acc;
  }, []);
 }


const superAdminMenuItems = filterOutMenu([
  configRoutesMenuLabels.organizationsManagementPage,
  configRoutesMenuLabels.superAdminUserManagementPage,
  configRoutesMenuLabels.corpOrganizationsManagementPage,
  configRoutesMenuLabels.reportsManagementPage,
  configRoutesMenuLabels.premiumPage,
  configRoutesMenuLabels.marketplaceManagementPage
])

const superAdminOrganizationOverrideMenuItems = filterOutMenu([
  configRoutesMenuLabels.contentManagementPage ,
  configRoutesMenuLabels.nutritionPlanPage ,
  configRoutesMenuLabels.clientManagementPage ,
  configRoutesMenuLabels.configurationManagementPage ,
  configRoutesMenuLabels.myOrganizationPage ,
  configRoutesMenuLabels.tutorialsPage,
  configRoutesMenuLabels.premiumPage,
  configRoutesMenuLabels.marketplaceManagementPage
])


const adminMenuItems = filterOutMenu([
  configRoutesMenuLabels.schedulePage ,
  configRoutesMenuLabels.contentManagementPage ,
  configRoutesMenuLabels.nutritionPlanPage ,
  configRoutesMenuLabels.clientManagementPage ,
  configRoutesMenuLabels.editProfilePage ,
  configRoutesMenuLabels.configurationManagementPage ,
  configRoutesMenuLabels.myOrganizationPage ,
  configRoutesMenuLabels.tutorialsPage ,
  configRoutesMenuLabels.billingPage ,
  configRoutesMenuLabels.stripeConnectPage ,
  configRoutesMenuLabels.marketplaceManagementPage
])

const conditionalAdminMenuItem = filterOutMenu([
  configRoutesMenuLabels.schedulePage ,
  configRoutesMenuLabels.contentManagementPage ,
  configRoutesMenuLabels.nutritionPlanPage , 
  configRoutesMenuLabels.clientManagementPage,
  configRoutesMenuLabels.editProfilePage, 
  configRoutesMenuLabels.configurationManagementPage , 
  configRoutesMenuLabels.myOrganizationPage, 
  configRoutesMenuLabels.editOrgProfilePage, 
  configRoutesMenuLabels.tutorialsPage ,
  configRoutesMenuLabels.billingPage ,
  configRoutesMenuLabels.stripeConnectPage ,
  configRoutesMenuLabels.marketplaceManagementPage
]);

const adminMenuItemsWithoutBilling = filterOutMenu([
  configRoutesMenuLabels.schedulePage ,
  configRoutesMenuLabels.contentManagementPage ,
  configRoutesMenuLabels.nutritionPlanPage , 
  configRoutesMenuLabels.clientManagementPage,
  configRoutesMenuLabels.editProfilePage, 
  configRoutesMenuLabels.configurationManagementPage ,
  configRoutesMenuLabels.myOrganizationPage, 
  configRoutesMenuLabels.stripeConnectPage ,
  configRoutesMenuLabels.tutorialsPage ,
  configRoutesMenuLabels.marketplaceManagementPage
]);

const conditionalAdminMenuItemsWithoutBilling = filterOutMenu([
  configRoutesMenuLabels.schedulePage ,
  configRoutesMenuLabels.contentManagementPage ,
  configRoutesMenuLabels.nutritionPlanPage , 
  configRoutesMenuLabels.clientManagementPage,
  configRoutesMenuLabels.editProfilePage, 
  configRoutesMenuLabels.configurationManagementPage ,
  configRoutesMenuLabels.myOrganizationPage, 
  configRoutesMenuLabels.editOrgProfilePage ,
  configRoutesMenuLabels.stripeConnectPage ,
  configRoutesMenuLabels.tutorialsPage ,
  configRoutesMenuLabels.marketplaceManagementPage
  ]);

const trainerMenuItems = filterOutMenu([
  configRoutesMenuLabels.schedulePage ,
  configRoutesMenuLabels.contentManagementPage ,
  configRoutesMenuLabels.nutritionPlanPage , 
  configRoutesMenuLabels.clientManagementPage ,
  configRoutesMenuLabels.editProfilePage ,
  configRoutesMenuLabels.tutorialsPage ,
  configRoutesMenuLabels.marketplaceManagementPage
]);

const marketplaceMenuItems = filterOutMenu([
  configRoutesMenuLabels.marketplaceManagementPage ,
])

const clientMenuItems = filterOutMenu([
  configRoutesMenuLabels.clientLandingPage ,
  configRoutesMenuLabels.marketplaceManagementPage,
]);

const filterOutStageEnv = (a :{
    label: string;
    route: string;
    icon: JSX.Element;
  }[]) =>{
    return a
    // uncomment bellow and chage configMainRoutesConsts if there is a page that needs to be hidden on prod.
    // return a.filter(e=>{
    //   if(!isStageEnv){
    //     if([
    //       configMainRoutesConsts.stripeConnect,
    //       configMainRoutesConsts.marketplace
    //       //@ts-ignore
    //     ].includes(e.route)){
    //       return false
    //     } 
    //   }
    //   return true
    // })
}

const menuItemsFor = (role?: Role, organization?: Organization, numberOfTrainers?: number, numberAdmins?: number) => {
  switch (role) {
    case Role.SuperAdmin:
      return filterOutStageEnv(superAdminMenuItems);
    case Role.Admin:
      // @ts-ignore
      if (organization?.free && (numberAdmins > 1 || numberOfTrainers > 0)) {
        // console.log("conditionalAdminMenuItemsWithoutBilling - bez bill, SA orgom");
        return filterOutStageEnv(conditionalAdminMenuItemsWithoutBilling);
      // @ts-ignore
      } else if (organization?.free && (numberAdmins === 1 || numberOfTrainers === 0)) {
        // console.log("adminMenuItemsWithoutBilling - bez bill, BEZ orgom");
        return filterOutStageEnv(adminMenuItemsWithoutBilling);
      // @ts-ignore
      } else if (!organization?.free && (numberAdmins > 1 || numberOfTrainers > 0)) {
        // console.log("conditionalAdminMenuItem - SA bill, SA orgom");
        return filterOutStageEnv(conditionalAdminMenuItem);
      // @ts-ignore
      } else if (!organization?.free && (numberAdmins === 1 || numberOfTrainers === 0)) {
        // console.log("adminMenuItems - SA bill, BEZ orgom");
        return filterOutStageEnv(adminMenuItems);
      }
    case Role.Trainer:
    case Role.AssistantTrainer:
      return filterOutStageEnv(trainerMenuItems);
    case Role.Client:
      return filterOutStageEnv(clientMenuItems);
    default:
      return [];
  }
}

const Unexpected = () => {
  const classes = useStyles();
  const {formatMessage} = useIntl();
  const handleLogout = () => {
    auth.signOut().then(
    ).catch((err) => console.log('Error signing out', err));
  };

  return (
    <div className={classes.error}>
      <Typography variant='body1' style={{marginBottom: '2em', whiteSpace: 'pre-line', textAlign: 'center'}}>
        {formatMessage(componentMessages.access_is_invitation_only)}
      </Typography>
      <Button color="primary" variant='text' onClick={handleLogout}>{formatMessage(globalMessages.back_to_sign_in)}</Button>
    </div>
  )
}

const AppLayout = ({children}: AppLayoutProps) => {
  const classes = useStyles();
  const {user, organizationOverride, userRole} = useUserProvider();
  
  const currentUserRole = useMemo(() => {
    return user ? getCurrentUserRole(user, organizationOverride) : undefined;
  }, [user, organizationOverride]);

  const currentOrganization = useStoreState(globalState, s => s.currentOrganization);
  const [numberTrainers, setNumberTrainers] = useState(-1);
  const [numberAdmins, setNumberAdmins] = useState(-1);
  const isMarketplace = isMarketplacePage();
  const location = useLocation();

  const menuType : MenuType  = useMemo(() => {
    return (currentUserRole&&currentUserRole===Role.Client) || !currentUserRole?'clientMenu':undefined
  }, [currentUserRole]);

  useEffect(() => {
    new Promise(async (resolve, reject) => {
      firestore
        .collection("organizationUser")
        .where("orgId", "==", currentOrganization?.id || "")
        .where("role", "==", "Trainer")
        .where("archived","==", false)
        .get()
        .then((querySnapshot) => {
          const data: Array<User> = [];
          querySnapshot.forEach((snapshot) => {data.push({id: snapshot.id, ...snapshot.data()} as User)});
          setNumberTrainers(data.length);
          resolve(data);
        })
        .catch((err) => {
            console.log(err);
            reject({success: false});
          }
        )
    })
  }, [currentOrganization]);

  useEffect(() => {
    new Promise(async (resolve, reject) => {
      firestore
        .collection("organizationUser")
        .where("orgId", "==", currentOrganization?.id || "")
        .where("role", "==", "Admin")
        .where("archived","==", false)
        .get()
        .then((querySnapshot) => {
          const data: Array<User> = [];
          querySnapshot.forEach((snapshot) => {data.push({id: snapshot.id, ...snapshot.data()} as User)});
          setNumberAdmins(data.length);
          resolve(data);
        })
        .catch((err) => {
            console.log(err);
            reject({success: false});
          }
        )
    })
  }, [currentOrganization]);

  useEffect(()=>{
  
    //check if messaging is supported by the browser at first
    if(
      app.messaging.isSupported() &&
      userRole.isTrainerWithinOrg
      ){
      //When user gets loggedin create a token and register that token to the topic
      //topic = user id
      const messaging = app.messaging();
      messaging.getToken().then(async (token:any)=>{
          // subscribe to topic
          //send request to firebase functions
          //(messagingTopic:string, messagingToken:string)
          await setNotificationsToBeActive(user?.id || "",token );
          messaging.onMessage(payload=>{
            //TODO grab paylaod and display it via toast etc.
            console.log("onMessage: " , payload)
          })
        }).catch(err => {
          //blocked notifications by user
        })
    }

  },[user])

  const isWhiteLabel: boolean = useMfwThemeProvider().theme.isWhiteLabel;
  console.log(currentUserRole);
  const menuItems = isWhiteLabel 
    ? menuItemsFor(currentUserRole, currentOrganization, numberTrainers, numberAdmins).filter((mi)=>mi.label != "tutorials") 
    : menuItemsFor(currentUserRole, currentOrganization, numberTrainers, numberAdmins);
  
  return currentUserRole ? (
    <div className={classes.root}>
      <NavigationDrawer
      menuType={menuType}
      menuItems={organizationOverride ?
          (
          organizationOverride === process.env.REACT_APP_MFW_ORGANIZATION_ID?
            [...superAdminOrganizationOverrideMenuItems,{
              label: 'org_profile',
              route: '/orgProfile',
              icon: <ProfileIcon />,
            }]:
            superAdminOrganizationOverrideMenuItems
          )
        : menuItems}
      />
      <main className={classes.content}>
        <Box className={classes.container_top_menu}>
          <Box className={classes.sectionName}>
            <Box>{SectionTitleWithTextTutorialsContent(location.pathname)}</Box>
          </Box>
          {(userRole.isTrainerWithinOrg || user?.isSuperAdmin) && <OrganizationSwitcher />}
        </Box>
        {children}
      </main>
    </div>
  ) : user && user.id? (
    <div className={classes.root}>
      <NavigationDrawer
        menuItems={clientMenuItems}
        menuType={menuType}
      />
      <main className={classes.content}>
        {children}
      </main>
      </div>
  ):
  isMarketplace?
  <div className={classes.root}>
      <NavigationDrawer
        menuItems={marketplaceMenuItems}
      />
      <main className={classes.content}>
        {children}
      </main>
    </div>
  :<Unexpected/>;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      width: '100%',
    },
    content: {
      flex: 1,
      width: '90%',

      [theme.breakpoints.down('sm')]: {
        paddingTop: theme.spacing(8),
      },
    },
    offset: theme.mixins.toolbar,
    error: {
      display: 'flex',
      flexDirection: 'column',
      padding: '5em',
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center'
    },
    sectionName: {
      marginLeft: 10
    },
    container_top_menu:{
      display:"flex",
      flexDirection:"row",
      justifyContent:"space-between",
      alignItems:"center",
      maxWidth:'100%',
      padding:"10px"
    }
  }),
);

export default AppLayout;
