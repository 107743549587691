import React, {useState} from 'react';
import {useForm} from "react-hook-form";
import Button from "@material-ui/core/Button";
import {Box, createStyles, TextField, Theme, Typography, MenuItem} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import {Invitation, Role} from "myfitworld-model";
import {useOrganizationProvider} from "../../providers/OrganizationProvider";
import {useUserProvider} from "../../providers/UserProvider";
import firebase from "firebase";
import {makeStyles} from "@material-ui/core/styles";
import {validatePhoneNumber} from "../../validators/validatePhoneNumber";
import {validateEmail} from "../../validators/validateEmail";
import {sendInvitation} from "../../api/invitationsApi";
import LoadingSpinner from '../LoadingSpinner';
import { getUserByEmail, isEmailOrPhoneNumberInTheDatabase, updateInvitedUser } from '../../api/usersApi';
import globalState, { GlobalState } from '../../globalState';
import { useMfwThemeProvider } from 'myfitworld-utils';
import globalMessages from '../../messages';
import invitationMessages from './messages';
import { useIntl } from 'react-intl';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiTextField-root': {
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(3)
      },
    },
  }),
);

const InviteUserForm = (
  {onSubmit, roles, createActionLabel, invitationsBlocked}: Props
) => {

  const {register, errors, handleSubmit, reset} = useForm();
  const [loading, setLoading] = useState(false);
  const [disabledForm, setDisabledForm] = useState(false);
  const [emailError, setEmailError ] = useState<number>(4);
  const [role, setRole] = useState('');
  const [roleError, setRoleError] = useState(false);

  const classes = useStyles();
  const {formatMessage} = useIntl();
  const [{selectedOrganization}] = useOrganizationProvider();
  const {user} = useUserProvider();
  const theme = useMfwThemeProvider();

  const onSubmitHandler = async (data: any) => {
    if(role === '' && roles.length >1){
      setRoleError(true);
      return;
    }
    //check email/phone if they are taken
    setLoading(true);
    setDisabledForm(true);
    const dbStatus = await isEmailOrPhoneNumberInTheDatabase(data.emailOrPhone.trim().toLowerCase(), user?.currentOrganization!!, theme.theme.orgId, role === "" ? 'Client' : role);
    // 0 - nije registrovan
    // 1 - zauzet od strane druge org ili ima pozivnicu od druge org
    // 2 - vec pripada ovoj org
    // 3 - vec ima pozivnicu od ove org
    //4 - user postoji, ali bez trenera
    if(dbStatus !== 4){
      setLoading(false);
      setDisabledForm(false);
      setEmailError(dbStatus);
      return;
    } 

    const invitedUser = await getUserByEmail(data.emailOrPhone.trim(), theme.theme.orgId);
    sendInvitation({
      firstName: invitedUser?.firstName,
      lastName: invitedUser?.lastName,
      email: validateEmail(data.emailOrPhone.trim().toLowerCase()) === true ? data.emailOrPhone.trim().toLowerCase() : null,
      phoneNumber: validatePhoneNumber(data.emailOrPhone.trim()) === true ? data.emailOrPhone.trim() : null,
      organization: roles[0] === Role.SuperAdmin ? null : selectedOrganization?.id || null,
      role: roles.length > 1 ? role : roles[0],
      createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
      invitedBy: user?.id,
      invitationSent: false,
      isInSendingQueue: true,
      acceptedOn: null,
      archived:false,
      rejected: false,
      userId: invitedUser?.id,
      userExist: true
    } as unknown as Invitation, roles.includes(Role.Client), selectedOrganization?.id ||"" ).then((result:boolean)=>{
      if(result){
        setLoading(false);
        setDisabledForm(false);
        updateInvitedUser(data.emailOrPhone, theme.theme.orgId);
        onSubmit();
        globalState.update((state: GlobalState) => {
          state.toastQueue.push({message: formatMessage(invitationMessages.the_invitation_has_been_sent_to_the_email) + " " + data.emailOrPhone + ".", severity: "success"});
        }); 
        reset();
        setRole("");
      }
    });
    
  };

  const getEmailErrorTextMessage = () => {
    if(errors.emailOrPhone){
      return errors.emailOrPhone?.type === 'required' ? formatMessage(globalMessages.required_field) : formatMessage(invitationMessages.invalid_email);
    }
    else{
      if(emailError === 0){
        return formatMessage(roles.length > 1 ? invitationMessages.this_email_is_not_exist_web : invitationMessages.this_email_is_not_exist);
      }
      else if(emailError === 1){
        return formatMessage(invitationMessages.this_email_is_already_connected_to_another_organization);
      }
      else if(emailError === 2){
        return formatMessage(invitationMessages.this_email_is_already_connected_to_your_organization);
      }
      else if(emailError === 3){
        return formatMessage(invitationMessages.this_email_is_already_invited_from_your_organization);
      } else if(emailError === 5) {
        return formatMessage(invitationMessages.client_account);
      } else{
        return null;
      }
    }
  }

  return (
    <form onSubmit={onSubmitHandler} className={classes.root}>

      {/* <TextField
        name="firstName"
        inputRef={register({
          required: true,
          pattern: {
            value: /^[\p{L}\s-]+$/u,
            message: formatMessage(invitationMessages.only_letters_are_allowed)
          },
        })}
        label={formatMessage(globalMessages.first_name)}
        error={!!errors.firstName}
        helperText={errors.firstName ? errors.firstName.type === 'required' ? formatMessage(globalMessages.required_field) : errors.firstName.message : null}
        fullWidth
        disabled={disabledForm}
        autoComplete='off'
      /> */}

      {/* <TextField
        name="lastName"
        inputRef={register({
          required: true,
          pattern: {
            value: /^[\p{L}\s-]+$/u,
            message: formatMessage(invitationMessages.only_letters_are_allowed),
          }
        })}
        label={formatMessage(globalMessages.last_name)}
        error={!!errors.lastName}
        helperText={errors.lastName ? errors.lastName.type === "required" ? formatMessage(globalMessages.required_field) : errors.lastName.message : null}
        fullWidth
        disabled={disabledForm}
        autoComplete='off'
      /> */}

      <TextField
        name="emailOrPhone"
        inputRef={register({
          required: true,
          pattern: {
            value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            message: formatMessage(invitationMessages.invalid_email)
          }
        })}
        error={!!errors.emailOrPhone || emailError !== 4}
        helperText={getEmailErrorTextMessage()}
        label={formatMessage(globalMessages.email)}
        fullWidth
        disabled={disabledForm}
        autoComplete='off'
        onChange={() => setEmailError(4)}
      />


      {roles.length > 1 &&
        <TextField
          select
          fullWidth
          label={formatMessage(globalMessages.role)}
          value={role !== '' ? formatMessage((invitationMessages as any)[role.toLowerCase()]): ''}
          error={roleError}
          disabled={disabledForm}
          helperText={roleError ? formatMessage(globalMessages.required_field) : null}
        >
          {
            roles.map((role) => (
              <MenuItem key={role} value={formatMessage((invitationMessages as any)[role.toLowerCase()])} onClick={() => {setRole(role);setRoleError(false);}}>
                {formatMessage((invitationMessages as any)[role.toLowerCase()])}
              </MenuItem>
            ))
          }
        </TextField>
      }
     {!loading && 
        <Box style={{margin: 24}} onClick={()=>{setRoleError(role==='')}}>
          <Button
            color='primary'
            variant='contained'
            onClick={handleSubmit(onSubmitHandler)}
            disabled={loading || invitationsBlocked}
          >
            {formatMessage((invitationMessages as any)[createActionLabel])}
          </Button>
        </Box>
      }
    {loading &&
        <Box style = {{margin:"20px"}}>
          <LoadingSpinner marginTop={1}/>
          <Typography variant='h5' style={{textAlign:'center', color: theme.mfwMuiWebTheme.palette.primary.main}}>{formatMessage(invitationMessages.sending_connection_request)}</Typography>
        </Box>
     }
      {roles.length === 1 &&
        <Alert severity="info" style = {{marginBottom:"10px"}}>
          <span>{formatMessage(invitationMessages.create_client_help_text)}</span><br></br>
          <span style={{fontStyle:"italic"}}>{formatMessage(invitationMessages.create_client_spam_text)}</span>
        </Alert>
      }
      {roles.length === 1 &&
        <Alert severity="info">    
          <span style={{fontWeight: "bold"}}>{formatMessage(invitationMessages.create_client_help_text_bold)}</span>
        </Alert>
      }
      {invitationsBlocked &&
      <Box mt={1}>
        <Alert severity="warning">
          {formatMessage(invitationMessages.invitation_disabled)}
        </Alert>
      </Box>
      }

    </form>
  )
}

export default InviteUserForm;

interface Props {
  roles: Role[];
  onSubmit: () => void;
  createActionLabel: string;
  helperText?: string;
  invitationsBlocked?: boolean;
}
