import { Avatar, Badge, Box, Typography, createStyles, makeStyles } from '@material-ui/core';
import { Activity } from 'myfitworld-model';
import React, { useEffect, useState } from 'react';
import { firestore } from '../../firebase';
import { User } from 'myfitworld-model/src';
import { DoneAll } from '@material-ui/icons';
import { useUserProvider } from '../../providers/UserProvider';
import { ProviderThemeType, useMfwThemeProvider } from 'myfitworld-utils';
import SimpleMenu from '../SimpleMenu';
import { ChatPreferenceOptionsProps } from '../chat/PreferenceChatDialog';
import { chatPreferences } from './preferenceFunctions';
const createStylesForTheme = (theme: ProviderThemeType) =>
    makeStyles(() =>
      createStyles({
        chatContainer :{
            border: `1px solid ${theme.theme.text.primary}`,
            borderRadius: 5, 
            padding: 10, 
            cursor: 'pointer', 
            marginTop: 5,
            display:'flex',
            flexDirection:'row',
            alignItems:"center",
            justifyContent:"space-between",
        },
        rowDisplay:{
            display:'flex',
            flexDirection:'row',
        },
        columnDisplay:{
            display:'flex',
            flexDirection:'column',
            alignItems:'flex-start',
            justifyContent:'flex-start',
        },
        avatar:{
            width: 40, 
            height: 40,
        },
        name:{
            color:"inherit",
            variant:"h5",
            marginLeft: 10,
            fontWeight: 'bold',
            display:'flex',
            flexDirection:'row',
        },
        messageContainer:{
            display:'flex',
            flexDirection:'row',
            justifyContent:'space-between',
            marginLeft:'14px',
        },
        typography_time:{
            fontSize:'13px',
            fontStyle:"italic"
        },
        container_right:{
            
            minWidth:"20%",
            display:"flex",
            flexDirection:"column",
            alignItems:"flex-end"
        }
      })
    );

const LastMessage = ({userId, unread, message, onClick,setChatPreferenceDialog, options}: {
    userId: string, 
    unread: number, 
    message: Activity, 
    onClick: (userId: string, userName: string) => void, 
    setChatPreferenceDialog:({isOpen, selectedProp}:{isOpen:boolean, selectedProp: ChatPreferenceOptionsProps | undefined})=>void,
    options:any,
}) => {

    const [user, setUser] = useState<User>();
    const {theme}= useMfwThemeProvider();
    const {user: authUser} = useUserProvider();
    const useStyles = createStylesForTheme(theme);
    const classes = useStyles();

    useEffect(() => {
        firestore.collection('users').doc(userId).get().then(
            response => setUser(response.data() as User)
        )
    },[]);

    function handleSelect(label:string | undefined){
        let selectedDialog:ChatPreferenceOptionsProps|undefined = chatPreferences.find((cp)=> cp.label===label);
        if(selectedDialog){
            selectedDialog.selectedUserId = userId;
            selectedDialog.selectedUserName = `${user?.firstName}  ${user?.lastName}`;
            setChatPreferenceDialog({isOpen: true, selectedProp: selectedDialog});
        }
    }

    function formatTime(inputDate: Date){    
        const currentDate = new Date();

        // Check if the input date is today
        const isToday = inputDate.toDateString() === currentDate.toDateString();
    
        if (isToday) {
            // Format as hh:mm
            return inputDate.toLocaleTimeString("de-DE", { hour: "2-digit", minute: "2-digit" });
        } else {
            // Format as dd.mm.yyyy
            return inputDate.toLocaleDateString("de-DE");
        }
    }
    
    return(
        <Box  className = {classes.chatContainer} >
            <Box onClick={() => onClick(userId, `${user?.firstName} ${user?.lastName}`)} className = {classes.rowDisplay}>
                {/*Avatar:*/}
                <Avatar className={classes.avatar} src={user?.avatarURL}/>
                {/* Name and text: */}
                <Box className={classes.columnDisplay}>
                    <Typography className={classes.name}>
                        {user?.firstName + " " +user?.lastName}
                    </Typography>
                    <Box className={classes.messageContainer} >
                        {message.senderId === authUser?.id && <DoneAll color={message.read ? 'primary' : 'disabled'} style={{fontSize: 15, marginTop: 1}}/>}
                        <Typography style={{fontSize: 13, marginLeft: 3}}>{message.text.length > 50 ? `${message.text.slice(0, 50)}...` : message.text}</Typography>
                    </Box>
                    <Badge badgeContent={unread} color="error"></Badge>
                </Box>
            </Box>
            {/* Archive or delete: */}
            <Box  className={classes.container_right}>
                <Typography className={classes.typography_time}>
                    {formatTime(message.createdAt)}
                </Typography>
                <Box style = {{width:"fit-content"}}>
                <SimpleMenu
                    options={options}
                    onSelect={(label)=>{handleSelect(label)}}
                    disabled={false}
                />
                </Box>
            </Box>
        </Box>
    );
};
export default LastMessage;